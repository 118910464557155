import { useEffect, useRef } from "react";
import Divider from "@mui/material/Divider";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export default function ChatSessionHistory({ chatHistory }) {
  const containerRef = useRef(null);

  console.log('ChatSessionHistory')
  useEffect(() => {
    const container = containerRef.current;
    container.scrollTop = container.scrollHeight;
  }, [chatHistory]);

  if (chatHistory.length === 0) {
    return <div ref={containerRef}></div>;
  }

  const renderers = {
    // Custom renderer for links (a elements)
    a: ({ href, children }) => (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    ),
  };

  return (
    <div ref={containerRef} style={{ maxHeight: "50vh", overflowY: "auto" }}>
      {chatHistory.map((e, i) => {
        //console.log('e', e)
        return (
          <div key={i}>
            <Divider style={{ paddingTop: 5, paddingBottom: 5 }} />
            <div style={{ paddingTop: 5 }}>
              <ReactMarkdown remarkPlugins={[remarkGfm]} components={renderers}>{e}</ReactMarkdown>
            </div>
          </div>
        )
      }
      )}
      <Divider style={{ paddingTop: 5, paddingBottom: 5 }} />
    </div>
  );
}
